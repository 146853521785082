<script lang="ts">
  import FileUploader from '@components/FileUploader.svelte';
</script>

<div id="container" class="mx-auto text-center max-w-[800px] mt-32">
  <h1 class="text-xl mb-10">Fully decentralized image convertor using Acurast x Cere</h1>
  <FileUploader />
  <div id="result">

  </div>
</div>
